function setPhoneCode() {
   const forms = document.querySelectorAll('.wpcf7-form')

   // Якщо форм немає, немає сенсу виконувати код
   if (!forms) return

   forms.forEach(form => {
      const phoneInput = form.querySelector('[name="Phone"]')
      const phoneInputNoCode = form.querySelector('[name="Phone_no_code"]')

      if (!phoneInput || !phoneInputNoCode) return

      const updatePhoneValue = () => {
         const selectedFlagEl = form.querySelector('.selected-flag')

         if (selectedFlagEl) {
            const titleAttr = selectedFlagEl.getAttribute('title')
            const codeValue = titleAttr.split(': ')[1].trim()
            const newValue = codeValue + phoneInputNoCode.value
            setValue(phoneInput, newValue)
         } else {
            console.warn('Не знайдено елемент з класом .selected-flag')
         }
      }
      updatePhoneValue()
      phoneInputNoCode.addEventListener('input', updatePhoneValue)
      phoneInputNoCode.addEventListener('focus', updatePhoneValue)
   })

   function setValue(input, value) {
      input.value = value
   }
}

window.addEventListener('load', setPhoneCode)
